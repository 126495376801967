* {
  color: white;
}
.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.hero-section-content {
  margin-top: 70px;
  backdrop-filter: blur(5px);
  background-color: rgba(194, 243, 255, 0.07);
  border-radius: 20px;
  width: 94%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-wrapper {
  padding-left: 5%;
  width: 60%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.text-wrapper .name {
  font-size: 7.5vw;
  margin: 3rem 0;
}
.text-wrapper .description {
  overflow: none;
}
.text-wrapper .description {
  padding-right: 5rem;
}
.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  object-fit: cover;
}
