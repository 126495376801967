*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: ethnocentric, sans-serif;
  font-style: normal;
}
body{
  width: 100%;
  height: 100%;
  background-image: url(heroBackground.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}