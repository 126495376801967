li a.active {
  color: #1da8d3;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 4%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-links {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.header-links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.nav-link {
  color: white;
  padding: 0.5rem;
  font-size: 24px;
  text-decoration: none;
}
