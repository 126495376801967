:root {
  --background-rgb: 29 30 34;

  --blue-rgb: 33 150 243;
  --primary-rgb: var(--blue-rgb);

  --blob-color-1: rgb(var(--blue-rgb));
  --blob-color-2: dodgerblue;
}

.screen {
  display: flex;
  border: 3px solid rgb(var(--primary-rgb) / 80%);
  height: 90%;
  aspect-ratio: 10 / 16;
  border-radius: 1rem;
  background-color: rgb(var(--primary-rgb) / 15%);
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.screen:after,
.screen:before {
  content: "";
  height: 5px;
  position: absolute;
  z-index: 4;
  left: 50%;
  translate: -50% 0%;
  background-color: white;
}

.screen:before {
  width: 15%;
  top: 0rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.screen:after {
  width: 25%;
  bottom: 0rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

@keyframes pan-overlay {
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: 0% -100%;
  }
}
.screen-overlay {
  background: linear-gradient(
    rgb(var(--primary-rgb) / 0.15),
    rgb(var(--primary-rgb) / 0.15) 3px,
    transparent 3px,
    transparent 9px
  );
  background-size: 100% 9px;
  height: 100%;
  width: 100%;
  animation: pan-overlay 22s infinite linear;
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 0px;
}
.screen > .screen-image {
 
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  filter: sepia(100%) hue-rotate(160deg);
  opacity: 0.6;
}
.screen > .screen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 4rem;
  position: relative;
  z-index: 3;
  margin: 1rem;
  padding-bottom: 1rem;
  border: 1px solid rgb(var(--primary-rgb) / 50%);
  border-radius: 0.6rem;
}
.screen > .screen-content > .screen-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.screen > .screen-content > .screen-user:before,
.screen > .screen-content > .screen-user:after {
  content: "";
  position: absolute;
  top: 0px;
  background-color: rgb(var(--primary-rgb));
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 3px rgb(var(--primary-rgb) / 60%);
}

.screen > .screen-content > .screen-user:before {
  height: 2px;
  width: 50px;
  translate: -20px -1rem;
  opacity: 0.75;
}

.screen > .screen-content > .screen-user:after {
  height: 3px;
  width: 30px;
  translate: 26px calc(-1rem - 0.5px);
}

.screen > .screen-content > .screen-user > .name {
  position: relative;
  font-size: 4.25rem;
  font-weight: 400;
   font-family: "Source Code Pro", monospace;
  color: white; 
  text-align: center;
  text-transform: uppercase; 
}